<template>
  <modal-form
    :value="value"
    id="edit-client-summary-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
    ref="form"
  >
    <template #header>
      {{ $t('clientSummary.editModal.modalTitle') }}
    </template>

    <template #fields="{ errorMessages }">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('clientSummary.clientDetailsSubSection') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <HubTextField
              label="Huboo Box"
              readonly
              disabled
              v-model="formData.hubooBox"
              dense
              name="huboo-box"
              :error-messages="(errorMessages && errorMessages.hubooBox) || []"
            />
          </v-col>
          <v-col cols="6">
            <HubTextField
              label="Invoice Name"
              readonly
              disabled
              v-model="formData.invoiceName"
              dense
              name="invoice-name"
              :error-messages="(errorMessages && errorMessages.invoiceName) || []"
            />
          </v-col>
          <v-col cols="6">
            <HubTextField
              @blur="onInputBlur"
              @keyup="onInputFocus('invoicePreference')"
              label="Invoice Preference"
              readonly
              v-model="formData.invoicePreference"
              dense
              name="invoice-preference"
              :error-messages="(errorMessages && errorMessages.invoicingPreference) || []"
            />
          </v-col>
          <v-col cols="6">
            <HubTextField
              @blur="onInputBlur"
              label="Invoice Email"
              readonly
              v-model="formData.invoiceEmail"
              dense
              name="invoice-email"
              :error-messages="(errorMessages && errorMessages.invoiceEmail) || []"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('clientSummary.billingDetailsSubSection') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <HubSelectField
              class="form-drop-down"
              name="billingType"
              v-model="formData.billingType"
              :items="billingTypeItems"
              :label="$t('clientSummary.billingType')"
              :error-messages="getErrorMessages('billingType')"
            />
          </v-col>
          <v-col cols="4">
            <store-data-dropdown
              class="form-drop-down"
              name="currency"
              v-model="formData.currency"
              data-getter="billing/getOriginRegions"
              item-value-key="currency"
              item-text-key="currency"
              :label="$t('billing.currency')"
              :error-messages="getErrorMessages('currency')"
              :disabled="!getIsAdmin"
            />
          </v-col>
          <v-col cols="4">
            <HubSelectField
              class="form-drop-down"
              name="accountProtection"
              v-model="formData.accountProtection"
              :items="accountProtectionItems"
              :label="$t('clientSummary.accountProtection')"
              :error-messages="getErrorMessages('accountProtection')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <store-data-dropdown
              class="form-drop-down"
              name="billingRegionDropdown"
              v-model="formData.billingRegion"
              data-getter="billing/getOriginRegions"
              item-value-key="code"
              item-text-key="name"
              :label="$t('clientSummary.billingRegion')"
              :error-messages="getErrorMessages('billingRegion')"
            />
          </v-col>
          <v-col cols="8">
            <OperatingRegionsMultiselect
              class="form-drop-down"
              ref="operatingRegionDropdown"
              @reset="onInputFocus('operatingRegions')"
              @operatingRegion="updateRegionMultiSelect($event)"
              :error-messages="errorMessages"
              :items="operatingRegions"
              :current-region="formData.operatingRegions"
              :disabled="loading"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('clientSummary.pickingDetailsSubSection') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <HubTextField
              @blur="onInputBlur"
              @keyup="onInputFocus('perItemLimit')"
              label="Per Item Limit"
              v-model="formData.perItemLimit"
              dense
              name="per-item-limit"
              :error-messages="(errorMessages && errorMessages.perItemLimit) || []"
              :rules="perItemLimitRules"
            />
          </v-col>
          <v-col cols="4">
            <HubSelectField
              class="form-drop-down"
              name="storageType"
              v-model="formData.storageType"
              :items="storageTypeItems"
              :label="$t('billing.storageType')"
              :error-messages="getErrorMessages('storageType')"
              :disabled="!getIsAdmin"
            />
          </v-col>
          <v-col cols="4">
            <HubTextField
              @blur="onInputBlur"
              @keyup="onInputFocus('storageDiscount')"
              :label="$t('billing.storageDiscount')"
              v-model="formData.storageDiscount"
              dense
              name="storage-discount"
              :error-messages="(errorMessages && errorMessages.storageDiscount) || []"
              :rules="intToHundredRules"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <HubTextField
              @keyup="onInputFocus('multiItemDiscount')"
              @blur="onInputBlur"
              :label="$t('billing.multiItemDiscount')"
              v-model="formData.multiItemDiscount"
              dense
              name="multi-item-discount"
              :error-messages="(errorMessages && errorMessages.multiItemDiscount) || []"
              :rules="intToHundredRules"
            />
          </v-col>
          <v-col cols="4">
            <HubSelectField
              class="form-drop-down"
              name="pickRate"
              v-model="formData.pickRateTypeId"
              :items="pickRateTypeItems"
              :label="$t('clientSummary.pickRateType')"
              :error-messages="getErrorMessages('pickRateTypeId')"
            />
          </v-col>
          <v-col cols="4">
            <HubSwitch
              @input="toggleFirstPickFree"
              :value="formData.firstPickFree"
              :label="$t('billing.firstPickFree')"
              name="first-pick-free-toggle"
              :error-messages="(errorMessages && errorMessages.firstPickFree) || []"
              :disabled="!getIsAdmin"
            />
          </v-col>
          <v-col cols="4">
            <HubSwitch
              @input="toggleAllowXMonthsFreeStorage"
              :value="formData.allowXMonthsFreeStorage"
              :label="$t('billing.allowXMonthsFreeStorage')"
              name="allow-x-months-free-storage-toggle"
              :error-messages="(errorMessages && errorMessages.allowXMonthsFreeStorage) || []"
              :disabled="!getIsAdmin"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('clientSummary.priceListVersion') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <HubSelectField
              @change="clearErrorMessages('priceListVersionId')"
              class="form-drop-down"
              name="PriceListVersion"
              v-model="formData.priceListVersionId"
              :items="availablePriceListVersions"
              :label="$t('clientSummary.priceListVersion')"
              :error-messages="getErrorMessages('priceListVersionId')"
              :disabled="!getIsAdmin"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #form-actions>
      <hub-button
        name="cancel"
        variant="secondary"
        @click="onCancel"
        id="cancelButton"
        ref="cancel-button"
      >
        {{ $t('common.cancel') }}
      </hub-button>

      <hub-button
        name="save"
        @click="onSubmit"
        id="saveButton"
        ref="save-button"
        :loading="loading"
      >
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import OperatingRegionsMultiselect from '@/components/forms/OperatingRegionsMultiselect.vue'
import StoreDataDropdown from '@/components/forms/StoreDataDropdown.vue'
import {
  BILLING_TYPES,
  ACCOUNT_PROTECTION_TYPES,
  PICK_RATE_TYPES,
  STORAGE_TYPES,
} from '@/utilities/constants'
import { mapGetters } from 'vuex'
import sortBy from 'lodash/sortBy'

const { MULTI_ITEM, PICK_RATE } = BILLING_TYPES
const { FULL, HALF } = ACCOUNT_PROTECTION_TYPES
const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES
const { ITEM_STORAGE, LEGACY_STORAGE, REPLEN_STORAGE } = STORAGE_TYPES

import {
  isFloat,
  isInteger,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isRoundedToNDecimalPlaces,
} from '@/utilities/validations'

const defaultClientValues = {
  hubooBox: '',
  invoiceName: '',
  invoiceEmail: '',
  invoicePreference: '',
  billingType: '',
  currency: '',
  pickRateTypeId: '',
  billingRegion: '',
  operatingRegions: [],
  perItemLimit: null,
  firstPickFree: false,
  accountProtection: '',
  storageDiscount: 0,
  multiItemDiscount: 0,
  priceListVersionId: 0,
  storageType: LEGACY_STORAGE,
}

export default {
  name: 'ClientEditModal',
  components: {
    OperatingRegionsMultiselect,
    StoreDataDropdown,
  },
  props: {
    value: Boolean,
    client: { type: Object, required: true },
  },

  data() {
    return {
      activeField: '',
      componentKey: 0,
      formData: this.getInitialFormData(),
    }
  },

  computed: {
    ...mapGetters('billing', ['getPriceListVersions', 'getOriginRegions']),
    ...mapGetters('core', ['getIsAdmin', 'apiEndpointIsLoading', 'hasErrors']),

    accountProtectionItems() {
      return [
        { text: this.$t('common.full'), value: FULL },
        { text: this.$t('common.half'), value: HALF },
        { text: this.$t('common.none'), value: null },
      ]
    },

    availablePriceListVersions() {
      let sortedPriceListVersions = sortBy(
        this.getPriceListVersions?.data ?? [],
        version => -version.id
      )
      return sortedPriceListVersions.map(version => ({
        text: version.name,
        value: version.id,
      }))
    },

    billingTypeItems() {
      return [
        { text: this.$t('billing.multiItem'), value: MULTI_ITEM },
        { text: this.$t('billing.pickRate'), value: PICK_RATE },
      ]
    },

    loading() {
      return this.apiEndpointIsLoading({
        method: 'PATCH',
        url: 'client-billing-setups/' + this.client?.id,
      })
    },

    fields() {
      return Object.keys(this.formData)
    },

    intToHundredRules() {
      return [
        v => isInteger(v),
        v => isGreaterThanOrEqualToN(v, 0),
        v => isLessThanOrEqualToN(v, 100),
      ]
    },
    operatingRegions() {
      return this.getOriginRegions?.map(region => ({
        text: region.displayName,
        value: region.code,
      }))
    },
    perItemLimitRules() {
      return [
        v => !v || isFloat(v),
        v => !v || isGreaterThanOrEqualToN(v, 0),
        v => !v || isRoundedToNDecimalPlaces(v, 2),
      ]
    },
    pickRateTypeItems() {
      return [
        { text: this.$t('billing.container'), value: CONTAINER },
        { text: this.$t('billing.pickNumber'), value: PICK_NUMBER },
      ]
    },
    storageTypeItems() {
      return [
        { text: this.$t('billing.legacyStorage'), value: LEGACY_STORAGE },
        { text: this.$t('billing.itemStorage'), value: ITEM_STORAGE },
        { text: this.$t('billing.replenStorage'), value: REPLEN_STORAGE },
      ]
    },
    errorMessages() {
      if (!this.hasErrors) return
      return this.$store.getters['core/getErrors']?.[422].errors
    },

    resetErrors() {
      return this.$store.commit('core/resetErrors')
    },
  },

  methods: {
    getErrorMessages(fieldName) {
      if (!this.errorMessages) return []
      return this.errorMessages[fieldName] ?? []
    },

    clearErrorMessages(fieldName) {
      return this.resetErrors[fieldName]
    },

    async onSubmit() {
      const {
        currency,
        firstPickFree,
        allowXMonthsFreeStorage,
        priceListVersionId,
        storageType,
        ...formData
      } = this.formData

      const res = await this.$store.dispatch('billing/patchClientBillingSetups', {
        id: this.client?.id,
        data: this.getIsAdmin
          ? {
              currency,
              firstPickFree,
              allowXMonthsFreeStorage,
              priceListVersionId,
              storageType,
              ...formData,
            }
          : formData,
      })

      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },

    onInputFocus(inputName) {
      this.activeField = inputName
    },

    onInputBlur() {
      this.activeField = ''
    },

    onCancel() {
      // Reset form data
      this.activeField = ''
      this.componentKey = 0
      this.formData = this.getInitialFormData()

      this.$emit('close')
    },
    toggleAllowXMonthsFreeStorage() {
      this.formData.allowXMonthsFreeStorage = !this.formData.allowXMonthsFreeStorage
    },
    toggleFirstPickFree() {
      this.formData.firstPickFree = !this.formData.firstPickFree
    },
    updateRegionMultiSelect(e) {
      this.formData.operatingRegions = e
    },

    getInitialFormData() {
      return {
        ...defaultClientValues,
        hubooBox: this.client?.hubooBox ?? defaultClientValues.hubooBox,
        invoiceName: this.client?.invoiceName ?? defaultClientValues.invoiceName,
        invoiceEmail: this.client?.invoiceEmail ?? defaultClientValues.invoiceEmail,
        invoicePreference:
          this.client?.invoicingPreference ?? defaultClientValues.invoicePreference,
        billingType: this.client?.billingType ?? defaultClientValues.billingType,
        currency: this.client?.currency ?? defaultClientValues.currency,
        pickRateTypeId: this.client?.pickRateTypeId ?? defaultClientValues.pickRateTypeId,
        billingRegion: this.client?.billingRegion ?? defaultClientValues.billingRegion,
        operatingRegions: this.client?.operatingRegions ?? defaultClientValues.operatingRegions,
        perItemLimit: this.client?.perItemLimit ?? defaultClientValues.perItemLimit,
        firstPickFree: this.client?.firstPickFree ?? null,
        allowXMonthsFreeStorage: this.client?.allowXMonthsFreeStorage ?? null,
        accountProtection: this.client?.accountProtection ?? defaultClientValues.accountProtection,
        storageDiscount: this.client?.storageDiscount ?? defaultClientValues.storageDiscount,
        storageType: this.client?.storageType ?? defaultClientValues.storageType,
        multiItemDiscount: this.client?.multiItemDiscount ?? defaultClientValues.multiItemDiscount,
        priceListVersionId:
          this.client?.priceListVersionId ?? defaultClientValues.priceListVersionId,
      }
    },
  },
}
</script>

<style>
.form-drop-down {
  max-height: 3.5rem;
}
</style>
